<template>
  <Transition>
    <div v-if="show" key="modal-product" class="popup-container" style="z-index: 1005" @click.self="handleClose">
      <div v-if="product.id" class="popup-content">
        <div class="popup-title">
          <div class="inner">
            <h2>{{ product.title || "Неизвестный продукт" }}</h2>
          </div>
          <div class="close-modal" @click.prevent="handleClose">
            <img width="20" height="20" src="/images/close.svg" alt="" />
          </div>
        </div>
        <div class="popup-description">
          <Loader v-if="displayLoader" type="product" />
          <div class="popup-product">
            <div class="product-slider">
              <div class="slider-list">
                <div class="product-slider-block">
                  <Carousel ref="carousel" :mouse-drag="!!product.images?.length" :touch-drag="!!product.images?.length"
                    :wrap-around="!!product.images?.length">
                    <template #slides>
                      <Slide index="0" key="main">
                        <CommonImage :data="product.mainImage" :height="336" :width="336" @load="handleImageLoad(0)"
                          @error="handleImageError(0)" />
                      </Slide>
                      <Slide v-for="(image, index) in product.images" :key="index">
                        <CommonImage :data="image" :height="336" :width="336" @load="handleImageLoad(index)"
                          @error="handleImageError(index)" />
                      </Slide>
                    </template>
                  </Carousel>
                </div>
              </div>
              <template v-if="product.images?.length">
                <a href="#prev" class="slider-arrow prev" @click.prevent="slideTo('prev')">
                  <img width="20" height="20" src="/images/slider-arrow.svg" alt="" />
                </a>
                <a href="#next" class="slider-arrow next" @click.prevent="slideTo('next')">
                  <img width="20" height="20" src="/images/slider-arrow.svg" alt="" />
                </a>
              </template>
            </div>
            <div class="product-block">
              <div class="available">
                  <img
                    width="16"
                    height="16"
                    :src="product.isWeight ? '/images/weigher.svg' : '/images/box.svg'"
                    alt=""
                  />
                  {{ !!product.priceKilo ? `${product.unit} / ${priceFormat(product.priceKilo || 0)} за кг` : product.unit || "0 гр" }}
              </div>
              <a href="#share" class="share-link" @click.prevent="handleOpenSharingModal">
                <img width="16" height="16" src="/images/share/icon.svg" alt="" />
                Поделиться
              </a>
            </div>
            <div class="popup-product-count" :class="{ disabled: !product?.quantityStock }">
              <span class="minus" :class="{ hidden: !productFromCart?.count, disabled: loading }"
                @click="removeFromCart(product.id)">
                <img width="16" height="16" src="/images/minus.svg" alt="" />
              </span>
              <div style="display: flex" :title="productFromCart?.cost
                ? `Итого ${priceFormat(productFromCart.cost)}`
                : ''
                ">
                <div class="count">
                  {{ productFromCart?.count || 0 }}
                </div>
                <div class="divider">х</div>
                <div class="price">
                  {{ priceFormat(product.priceDiscount ?? product.price) }}
                </div>
              </div>
              <span class="plus" :class="{
                hidden: !product.quantityStock || limitExceeded,
                disabled: loading,
              }" @click="addToCart(product)">
                <img width="16" height="16" src="/images/plus.svg" alt="" />
              </span>
            </div>
            <p>
              {{ product.description || "Описание отсутствует." }}
            </p>
            <div class="seller-name">
              <img width="16" height="16" src="/images/store.svg" alt="">
              {{ product?.partner.title || product?.partner.slug }}
            </div>
            <p v-if="product.isWeight" class="note">
              *Точный вес и стоимость этих товаров будут получены вами после
              сборки заказа
            </p>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import "vue3-carousel/dist/carousel.css";

import { useStore } from "~/store";
import { useModalStore } from "~/store/modal";
import { useCartStore } from "~/store/cart";
import type { ProductInfo } from "~/types/types";

export default defineComponent({
  setup() {
    const route = useRoute();
    const { categoryMetaData, replaceUrlWithoutNavigation } = useSeoTags();

    const store = {
      cart: useCartStore(),
      main: useStore(),
      modal: useModalStore(),
    };
    const { addToCart, removeFromCart } = store.cart;
    const { openModal, closeModal } = store.modal;
    const { product, productLink, category, categories } = storeToRefs(
      store.main
    );
    const { modal } = storeToRefs(store.modal);

    // computed data
    const show = computed(() => {
      return modal.value.product;
    });

    // methods
    const handleClose = () => {
      /*
      const query = { ...route.query };
      delete query.product;
      router.replace({ query });
      */

      const { product } = storeToRefs(store.main);
      if (product.value?.id) {
        const { category: prevCategory } = route.params;

        const current = store.main.findCategoryBySlug(
          categories.value,
          (prevCategory as string) || product.value?.category?.slug || ""
        );

        if (route.name !== "index" && current) {
          replaceUrlWithoutNavigation(
            (prevCategory as string) || product.value?.category?.slug || ""
          );

          category.value = current;
          useHead(categoryMetaData(current));
        } else {
          replaceUrlWithoutNavigation();
          useHead(categoryMetaData());
        }
      }

      closeModal("product");
      store.main.product = {} as ProductInfo;
    };
    const handleOpenSharingModal = async () => {
      displayLoader.value = true;

      await new Promise((resolve) => setTimeout(resolve, 325));
      displayLoader.value = false;
      openModal("share");
    };

    // cart
    const loading = computed(() => {
      return store.cart.loading;
    });
    const productFromCart = computed(() => {
      const { cart } = store.cart;
      return cart.products?.find((p) => p?.id === product.value?.id) || null;
    });
    const limitExceeded = computed(() => {
      const { limits, cart } = store.cart;

      if (productFromCart.value) {
        const { count, quantityStock } = productFromCart.value;
        return count >= limits.maxProductCount || count >= quantityStock;
      } else {
        const length = cart.products.length;
        return length >= limits.maxProductsLength;
      }
    });

    // carousel (для него нет type, оставляем any)
    const carousel = ref<any>(null);
    const slideTo = (to: "prev" | "next") => {
      if (to === "prev") carousel.value.prev();
      else carousel.value.next();
    };
    /*
    const handleInit = () => {
      console.log(carousel.value);
    };
    */

    // loader/images ready
    const displayLoader = ref<boolean>(true);
    const loadedImagesIndex = ref<number[]>([]);
    const loadedCount = computed(() => {
      const mainImageCount = product.value?.mainImage ? 1 : 0;
      if (product.value?.images?.length) {
        return (
          mainImageCount + [...new Set(loadedImagesIndex.value)]?.length || 0
        );
      } else {
        return mainImageCount;
      }
    });
    const totalCount = computed(() => {
      const mainImageCount = product.value?.mainImage ? 1 : 0;
      if (product.value?.images?.length) {
        return mainImageCount + product.value.images.length;
      } else {
        return mainImageCount;
      }
    });
    const handleAllImagesLoaded = async () => {
      if (totalCount.value === loadedCount.value) {
        await new Promise((resolve) => setTimeout(resolve, 250));
        displayLoader.value = false;
      }
    };

    watch(
      () => show.value,
      (status: boolean) => {
        if (status) {
          productLink.value =
            typeof window != "undefined" ? window.location.href : "";
        } else {
          loadedImagesIndex.value = [];
          displayLoader.value = true;

          productLink.value = "";
        }
      }
    );
    const handleImageLoad = async (i: number) => {
      loadedImagesIndex.value.push(i);
      handleAllImagesLoaded();
    };
    const handleImageError = async (i: number) => {
      // даже если изображение не загружено, мы увеличиваем счетчик массива, чтобы знать - процесс попытки загрузки завершен
      loadedImagesIndex.value.push(i);
      handleAllImagesLoaded();
    };

    const { priceFormat } = usePriceFormat();

    return {
      show,
      product,
      displayLoader,
      loading,
      handleOpenSharingModal,
      handleClose,

      // cart
      productFromCart,
      limitExceeded,
      addToCart,
      removeFromCart,
      priceFormat,

      // carousel
      carousel,
      slideTo,

      // loader & image
      handleImageLoad,
      handleImageError,
    };
  },
});
</script>

<style lang="less" scoped>
.image-empty {
  background: #f2f7f9;
  height: 336px;
}

.disabled {
  cursor: not-allowed;
  filter: opacity(0.6);
}

// popup
.popup {
  &-container {
    img {
      user-select: none;
      pointer-events: none;
    }
  }

  &-product {
    .available {
      margin-bottom: 0;
    }
  }
}

.product-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

// share
.share-link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #7c8b94;
  // font-size: 14px;
  font-weight: 700;
  line-height: 1.3;
  transition: all 0.3s ease-out;

  &:hover {
    opacity: 0.7;
  }

  img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
}
</style>
