<template>
  <Transition>
    <div
      v-if="show"
      key="modal-example"
      class="popup-container"
      @click.self="handleClose"
    >
      <div class="popup-content">
        <div class="popup-title">
          <a
            href="#"
            class="link-back"
            :class="{ disabled: loading }"
            @click.prevent="mode ? handleChangeMode() : handleBack()"
          >
            <img width="20" height="20" alt="" src="/images/arrow-left.svg" />
          </a>
          <div class="inner">
            <template v-if="mode === 'cancel'">
              <h2>Отмена заказа</h2>
            </template>
            <template v-else-if="mode === 'change'">
              <h2>Изменение веса товара</h2>
              <div class="subtitle">
                <span>{{ editProduct.title || "Продукт" }}, </span>
                <span>Заказ №{{ order?.serialNumber || 0 }}</span>
                <span
                  v-if="order.user?.phoneNumber"
                  class="phone"
                  style="display: block"
                >
                  ({{ order.user.phoneNumber }})
                </span>
              </div>
            </template>
            <template v-else>
              <h2>
                Заказ №{{ order?.serialNumber || 0 }}
                <span
                  v-if="order.user?.phoneNumber"
                  class="phone"
                  style="display: block"
                >
                  ({{ order.user.phoneNumber }})
                </span>
              </h2>
              <div class="subtitle">
                от
                {{ $dayjs(order.createDT || null).format("DD.MM.YYYY HH:mm") }}
              </div>
            </template>
          </div>
          <CommonCloseButton />
        </div>
        <div
          v-if="order.products?.length || mode === 'cancel'"
          ref="popupContentRef"
          class="popup-description"
        >
          <template v-if="mode === 'cancel'">
            <div class="lk-content" style="position: relative">
              <Loader v-if="loading" />
              <div class="lk-orders-cancel">
                <a href="#" class="lk-orders-link" style="pointer-events: none">
                  <div :class="{ hidden: !order?.id }">
                    <div class="number">
                      Заказ №{{ order?.serialNumber || 0 }}
                    </div>
                    <div class="date">
                      от
                      {{
                        $dayjs(order.createDT || null).format(
                          "DD.MM.YYYY HH:mm"
                        )
                      }}
                    </div>
                    <div class="price">
                      Сумма {{ order.totalCost || 0 }} руб
                    </div>
                  </div>
                </a>
              </div>
              <form
                action="#"
                class="popup-form simple-form"
                @submit.prevent="handleSubmit('cancel')"
              >
                <div class="input-label">
                  Для отмены заказа введите причину отмены и нажмите кнопку
                  “Отменить заказ”
                </div>
                <div class="input-block">
                  <CommonTextarea
                    v-model="cancelComment"
                    placeholder="Введите причину отмены (обязательно)"
                    :max-length="1024"
                    :disabled="loading"
                  />
                </div>
                <button
                  type="submit"
                  :disabled="cancelComment?.trim()?.length < 5 || loading"
                  class="btn"
                >
                  Отменить заказ
                </button>
              </form>
            </div>
          </template>
          <template v-else-if="mode === 'change'">
            <div class="lk-content" style="position: relative">
              <Loader v-if="loading" />
              <form
                action="#"
                class="popup-form simple-form"
                @submit.prevent="handleSubmit('change')"
              >
                <div class="input-block">
                  <input
                    v-model="productWeight"
                    v-maska
                    data-maska="##########"
                    :disabled="loading"
                    type="tel"
                    placeholder="Введите вес товара в граммах"
                  />
                </div>
                <button
                  type="submit"
                  :disabled="productWeight?.trim()?.length < 3 || loading"
                  class="btn"
                >
                  Сохранить вес
                </button>
              </form>
            </div>
          </template>
          <template v-else-if="mode === 'remove'">
            <div class="lk-content">
              <div class="order-product-remove">
                <p style="font-weight: 500">
                  Вы действительно хотите удалить
                  <b>{{ editProduct.title }}</b> из заказа?
                </p>
                <div class="flex-block">
                  <a
                    href="#"
                    class="btn empty-btn"
                    :class="{ disabled: loading }"
                    @click.prevent="handleRemove()"
                  >
                    Да
                  </a>
                  <a
                    href="#"
                    class="btn"
                    :class="{ disabled: loading }"
                    @click.prevent="handleChangeMode()"
                  >
                    Нет
                  </a>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div style="position: relative">
              <Loader v-if="loading" />
              <div class="lk-order-open">
                <div
                  v-for="product in order.products"
                  :key="product.id"
                  class="cart-product"
                >
                  <div class="content">
                    <a
                      :href="`?product=${product.slug}`"
                      class="image"
                      @click.prevent="openProduct(product)"
                    >
                      <CommonImage
                        :data="product.mainImage"
                        :height="71"
                        :width="71"
                      />
                    </a>
                    <div class="inner">
                      <div class="name">
                        <a
                          :href="`?product=${product.slug}`"
                          :class="{ disabled: loading }"
                          @click.prevent="openProduct(product)"
                        >
                          {{ product.title ?? "Неизвестный продукт" }}
                        </a>
                      </div>
                      <div class="available">
                        <img
                          width="16"
                          height="16"
                          :src="product.isWeight ? '/images/weigher.svg' : '/images/box.svg'"
                          alt=""
                        />
                        {{ !!product.priceKilo ? `${product.unit} / ${priceFormat(product.priceKilo || 0)} за кг` : product.unit || "0 гр" }}
                      </div>
                      <div class="counting">
                        <div class="count-number">
                          {{ Number(product.count || 0) }}
                        </div>
                        <div class="price-block">
                          <div class="price">
                            {{
                              priceFormat(product.cost ?? product.price ?? 0)
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <a
                      v-if="order.products?.length > 1 && order.state == 30"
                      href="#"
                      class="remove"
                      @click.prevent="
                        () => {
                          editProduct = product;
                          handleChangeMode('remove');
                        }
                      "
                    >
                      <img
                        width="20"
                        height="20"
                        src="/images/close.svg"
                        alt=""
                      />
                    </a>
                  </div>
                  <button
                    v-if="order.state == 30 && product.isWeight"
                    class="btn empty-btn"
                    @click.prevent="
                      () => {
                        editProduct = product;
                        handleChangeMode('change');
                      }
                    "
                  >
                    Указать точный вес
                  </button>
                </div>
                <template v-if="order.state == 30">
                  <ul class="lk-info-list">
                    <li v-if="order.address">
                      <div class="type">Адрес доставки</div>
                      <div class="value">
                        {{ order.address || "-" }}
                      </div>
                    </li>
                    <li v-if="order.comment">
                      <div class="type">Комментарий от покупателя</div>
                      <div class="value">
                        {{ order.comment || "-" }}
                      </div>
                    </li>
                  </ul>
                  <form
                    action="#"
                    class="popup-form simple-form"
                    @submit.prevent="handleSubmit"
                  >
                    <button type="submit" :disabled="loading" class="btn">
                      Отправить на доставку
                    </button>
                  </form>
                </template>
                <template v-else>
                  <div
                    v-if="order.state == 50"
                    class="btn status-btn empty-orange-btn"
                  >
                    <img
                      width="25"
                      height="24"
                      src="/images/truck-orange.svg"
                      alt=""
                    />
                    Заказ в пути
                  </div>
                  <div
                    v-if="order.state == 100"
                    class="btn status-btn empty-orange-btn"
                  >
                    <img
                      width="25"
                      height="24"
                      src="/images/check-orange.svg"
                      alt=""
                    />
                    Заказ доставлен
                  </div>
                  <div
                    v-if="order.state == -100"
                    class="btn status-btn empty-gray-btn"
                  >
                    <img
                      width="25"
                      height="24"
                      src="/images/close-gray.svg"
                      alt=""
                    />
                    Заказ отменен
                  </div>
                  <ul class="lk-info-list">
                    <template v-if="order.state == -100">
                      <li v-if="order.cancelComment">
                        <div class="type">Причина отмены</div>
                        <div class="value">
                          {{ order.cancelComment || "-" }}
                        </div>
                      </li>
                      <li v-if="order.updateDT">
                        <div class="type">Отменен</div>
                        <div class="value">
                          {{
                            $dayjs(order.updateDT || new Date()).format(
                              "DD.MM.YYYY HH:mm"
                            ) || ""
                          }}
                        </div>
                      </li>
                    </template>
                    <template v-else>
                      <li v-if="order.address">
                        <div class="type">Адрес доставки</div>
                        <div class="value">
                          {{ order.address || "-" }}
                        </div>
                      </li>
                      <li v-if="order.comment">
                        <div class="type">Комментарий от покупателя</div>
                        <div class="value">
                          {{ order.comment || "-" }}
                        </div>
                      </li>
                      <li v-if="order.deliveryDT">
                        <div class="type">Отгружен</div>
                        <div class="value">
                          {{
                            $dayjs(order.deliveryDT || new Date()).format(
                              "DD.MM.YYYY HH:mm"
                            ) || ""
                          }}
                        </div>
                      </li>
                      <li v-if="order.successDT">
                        <div class="type">Доставлен</div>
                        <div class="value">
                          {{
                            $dayjs(order.successDT || new Date()).format(
                              "DD.MM.YYYY HH:mm"
                            ) || ""
                          }}
                        </div>
                      </li>
                    </template>
                  </ul>
                  <form
                    v-if="order.state == 50"
                    action="#"
                    method="post"
                    class="popup-form simple-form"
                    @submit.prevent="handleSubmit('finished')"
                  >
                    <button type="submit" class="btn" :disabled="loading">
                      Заказ выполнен
                    </button>
                  </form>
                </template>
              </div>
            </div>
          </template>
        </div>
        <div v-else>
          <div class="not-found-description" style="margin-top: 1rem">
            <div class="inner">
              <img
                width="30"
                height="30"
                src="/images/file-question.svg"
                alt=""
              />
              <p>Нет продуктов</p>
            </div>
          </div>
        </div>
        <div class="lk-order-bottom">
          <template v-if="!mode">
            <div class="total-price">
              Итого <span>{{ priceFormat(order.totalCost || 0) }}</span>
            </div>
            <button
              v-if="order.state == 30 || order.state == 50"
              class="btn empty-btn"
              :disabled="loading"
              @click.prevent="handleChangeMode('cancel')"
            >
              Отменить заказ
            </button>
          </template>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { useAdminStore } from "~/store/admin";
import { useModalStore } from "~/store/modal";

import type { ProductInfo } from "~/types/types";

type Mode = "cancel" | "change" | "remove" | null;

interface OrderForm {
  cancelComment: string;
  editProduct: ProductInfo;
  productWeight: string;
}

export default defineComponent({
  setup() {
    // route/router
    const router = useRouter();
    const route = useRoute();

    // store
    const store = {
      admin: useAdminStore(),
      modal: useModalStore(),
    };
    const { modal, openModal, closeModal } = store.modal;
    const { order, loading: adminLoading } = storeToRefs(store.admin);

    const popupContentRef = ref<HTMLDivElement | null>(null);
    const popupScrollTop = ref<number>(0);

    const loading = computed(() => {
      return adminLoading.value;
    });

    // form
    const form = reactive<OrderForm>({
      cancelComment: "",
      editProduct: {} as ProductInfo,
      productWeight: "",
    });
    const clearForm = () => {
      form.cancelComment = "";
      form.productWeight = "";
      form.editProduct = {} as ProductInfo;
    };

    const handleSubmit = async (type: Mode | "finished" = null) => {
      // cancel (Отменить заказ)
      if (type === "cancel") {
        const { cancelComment } = form;

        if (cancelComment?.trim()?.length) {
          try {
            store.admin.clearBody();
            store.admin.updateBody({ state: -100, cancelComment }, "order");
            const result = await store.admin.updateOrderState(order.value.id);

            if (result) {
              handleBack();
              form.cancelComment = "";
            }
          } catch (e) {
            console.error("handleSubmit error", e);
          }
        }
      }
      // change (Указать точный вес)
      else if (type === "change") {
        const { productWeight } = form;

        if (productWeight?.trim()?.length) {
          try {
            const gramsToKilograms = (grams: string) => {
              const kilograms = Number(grams) / 1000;
              return Number(kilograms.toFixed(3));
            };

            store.admin.clearBody();
            store.admin.updateBody(
              { count: gramsToKilograms(productWeight) },
              "order"
            );
            const result = await store.admin.updateOrderProduct(
              order.value.id,
              form.editProduct.id
            );

            if (result) {
              handleChangeMode();
            }
          } catch (e) {
            console.error("handleSubmit error", e);
          }
        }
      } else if (type === "finished") {
        try {
          store.admin.clearBody();
          store.admin.updateBody({ state: 100 }, "order");
          const result = await store.admin.updateOrderState(order.value.id);

          if (result) {
            handleBack(50);
          }
        } catch (e) {
          console.error("handleSubmit error", e);
        }
      } else {
        try {
          store.admin.clearBody();
          store.admin.updateBody({ state: 50 }, "order");
          const result = await store.admin.updateOrderState(order.value.id);

          if (result) {
            handleBack();
          }
        } catch (e) {
          console.error("handleSubmit error", e);
        }
      }
    };

    let timeout: NodeJS.Timeout | undefined;
    const mode = ref<Mode>(null);
    const handleChangeMode = async (m: Mode = null) => {
      if (m === null) {
        if (timeout) {
          clearTimeout(timeout);
        }

        timeout = setTimeout(() => {
          if (popupContentRef.value) {
            popupContentRef.value.scrollTop = popupScrollTop.value;
            popupScrollTop.value = 0;
          }
        }, 0);

        clearForm();
      } else if (m === "change") {
        const scrollTop = popupContentRef.value?.scrollTop;
        popupScrollTop.value = scrollTop || 0;
      }

      mode.value = m;
    };

    // modal
    const show = computed(() => {
      return modal.adminOrder;
    });
    const handleBack = (m: number = 30) => {
      if (loading.value) {
        return;
      }

      const query = { ...route.query };
      query.adminMode = String(order.value?.state || m);
      router.replace({ query });

      handleClose();
      openModal("adminOrders");
    };
    const handleClose = () => {
      closeModal("adminOrder");
    };

    // product
    const openProduct = async (openProduct: ProductInfo) => {
      try {
        if (openProduct) {
          // await store.main.fetchProductByArg(openProduct.slug);
          const { product } = storeToRefs(store.admin);

          product.value = openProduct;
          store.modal.openModal("openProduct");
        }
      } catch (e) {
        console.error("openProduct err", e);
      }
    };
    const handleRemove = async () => {
      try {
        const result = await store.admin.removeOrderProduct(
          order.value.id,
          form.editProduct.id
        );

        if (result) {
          handleChangeMode();
        }
      } catch (e) {
        console.error("handleRemove err", e);
      }
    };

    watch(
      () => show.value,
      async (status: boolean) => {
        if (!status) {
          mode.value = null;
          clearForm();
        }
      }
    );

    const { priceFormat } = usePriceFormat();

    return {
      ...toRefs(form),
      handleSubmit,

      popupContentRef,
      loading,
      order,
      mode,
      handleChangeMode,

      // modal
      show,
      handleBack,
      handleClose,

      // product
      openProduct,
      handleRemove,

      // format
      priceFormat,
    };
  },
});
</script>

<style lang="less" scoped>
.popup-form {
  input,
  textarea {
    text-align: left;
  }
}
/*
.subtitle {
  span {
    display: block;
    @media screen and (max-width: 767.98px) {
      display: inline;
      white-space: nowrap;
    }
  }
}
*/
</style>