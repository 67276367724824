<template>
  <Transition>
    <div v-if="show" class="popup-container" @click.self="handleClose">
      <template v-if="product?.id">
        <div class="popup-content">
          <div class="popup-title">
            <a href="#" class="link-back" @click.prevent="handleBack">
              <img width="20" height="20" alt="" src="/images/arrow-left.svg" />
            </a>
            <div class="inner">
              <h2>{{ product.title || "Продукт" }}</h2>
              <div class="subtitle">Редактирование</div>
            </div>
            <CommonCloseButton />
          </div>
          <div class="popup-description">
            <div class="lk-content" style="position: relative">
              <form
                action="#"
                method="post"
                class="popup-form simple-form"
                @submit.prevent="handleSubmit"
              >
                <div class="redact-block">
                  <div class="input-block">
                    <input
                      v-model="form.title"
                      :disabled="loading"
                      type="text"
                      :maxlength="100"
                      placeholder="Название товара"
                      @keydown="handlePrevent"
                    />
                  </div>
                  <ul class="lk-info-list">
                    <li>
                      <div class="type">Текущее название:</div>
                      <div class="value">
                        {{ product.title || "Название отсутствует." }}
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="redact-block">
                  <div class="input-block">
                    <CommonTextarea
                      v-model="form.description"
                      :disabled="loading"
                      :max-length="1000"
                      placeholder="Введите новое описание товара"
                    />
                  </div>
                  <ul class="lk-info-list">
                    <li>
                      <div class="type">Текущее описание:</div>
                      <div class="value">
                        {{ product.description || "Описание отсутствует." }}
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="redact-block">
                  <div class="input-block">
                    <CommonSelect
                      v-model="form.unit"
                      :options="packingOptions"
                      placeholder="Фасовка товара"
                    />
                  </div>
                  <ul class="lk-info-list">
                    <li>
                      <div class="type">Текущая фасовка:</div>
                      <div class="value">{{ product.unit || "0 кг" }}</div>
                    </li>
                  </ul>
                </div>
                <div class="redact-block">
                  <div class="input-block">
                    <input
                      v-model="form.price"
                      v-maska
                      data-maska="#######"
                      :disabled="loading"
                      type="tel"
                      placeholder="Цена за единицу товара"
                      @keydown="handlePrevent"
                    />
                  </div>
                  <ul class="lk-info-list">
                    <li>
                      <div class="type">Текущая цена:</div>
                      <div class="value">{{ priceFormat(product.price) }}</div>
                    </li>
                  </ul>
                </div>
                <div class="redact-block">
                  <div class="input-block">
                    <input
                      v-model="form.quantityStock"
                      v-maska
                      data-maska="####"
                      :disabled="loading"
                      type="tel"
                      placeholder="Товарный остаток в наличии"
                      @keydown="handlePrevent"
                    />
                  </div>
                  <ul class="lk-info-list">
                    <li>
                      <div class="type">Текущий остаток:</div>
                      <div class="value">
                        {{ product.quantityStock || 0 }} шт
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="redact-block">
                  <ul class="lk-info-list">
                    <li>
                      <div class="type">Весовой товар?</div>
                      <div class="value">
                        <div class="value-radio">
                          <div class="radio-block">
                            <label>
                              <input
                                v-model="form.isWeight"
                                type="radio"
                                name="product-is-weight"
                                :disabled="loading"
                                :value="true"
                              />
                              <i />
                              <span>Да</span>
                            </label>
                          </div>
                          <div class="radio-block">
                            <label>
                              <input
                                v-model="form.isWeight"
                                type="radio"
                                name="product-is-weight"
                                :disabled="loading"
                                :value="false"
                              />
                              <i />
                              <span>Нет</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div v-if="form.isWeight" class="redact-block">
                  <div class="input-block">
                    <input
                      v-model="form.priceKilo"
                      v-maska
                      data-maska="#######"
                      :disabled="loading"
                      type="tel"
                      placeholder="Цена за кг товара"
                      @keydown="handlePrevent"
                    />
                  </div>
                  <ul class="lk-info-list">
                    <li>
                      <div class="type">Текущая цена:</div>
                      <div class="value">{{ priceFormat(product.priceKilo) }}</div>
                    </li>
                  </ul>
                </div>
                <button :disabled="loading" type="submit" class="btn">
                  Сохранить
                </button>
              </form>
              <Loader v-if="loading" />
            </div>
          </div>
        </div>
      </template>
    </div>
  </Transition>
</template>

<script lang="ts">
import { useAdminStore } from "~/store/admin";
import { useModalStore } from "~/store/modal";

import type { ProductBody } from "~/types/types";

export default defineComponent({
  setup() {
    // store
    const store = {
      admin: useAdminStore(),
      modal: useModalStore(),
    };
    const { loading, edit, products } = storeToRefs(store.admin);
    const product = computed(() => {
      return edit.value.product;
    });

    // <>
    const form = ref<{
      title: string;
      description: string;
      unit: string;
      price: number | null;
      priceKilo: number | null;
      quantityStock: number | null;
      isWeight: boolean;
    }>({
      title: "",
      description: "",
      unit: "",
      price: null,
      priceKilo: null,
      quantityStock: null,
      isWeight: false,
    });
    const packingOptions = ref([
      /*
      {
        label: '',
        value: ''
      },
      */
      {
        label: "1 кг",
        value: "1 кг",
      },
      {
        label: "1 шт",
        value: "1 шт",
      },
      {
        label: "2 шт",
        value: "2 шт",
      },
      {
        label: "100 гр",
        value: "100 гр",
      },
      {
        label: "200 гр",
        value: "200 гр",
      },
      {
        label: "500 гр",
        value: "500 гр",
      },
      {
        label: "1 л",
        value: "1 л",
      },
      {
        label: "500 мл",
        value: "500 мл",
      },
      {
        label: "1 уп",
        value: "1 уп",
      },
    ]);
    const handleSubmit = async () => {
      store.admin.clearBody();
      const productId = product.value.id;
      if (productId) {
        loading.value = true;
        try {
          const filteredFields = Object.entries(form.value)
            .filter(
              ([key, value]) =>
                value !== null && value !== undefined && value !== ""
            )
            .reduce(
              (obj, [key, value]) => ({
                ...obj,
                [key]: typeof value === "string" ? value?.trim() : value,
              }),
              {}
            ) as ProductBody;

          store.admin.updateBody(filteredFields, "product");
          const updatedProduct = await store.admin.updateProduct(productId);
          if (updatedProduct?.id) {
            handleBack();
            if (products.value?.length) {
              const index = products.value.findIndex((product) => product.id);
              if (index !== -1) {
                products.value[index] = updatedProduct;
              }
            } else {
              await store.admin.fetchAdminProducts();
            }
          }
        } catch (e) {
          console.error("handleSubmit error", e);
        } finally {
          loading.value = false;
        }
      }
    };
    const handlePrevent = (event: KeyboardEvent) => {
      // блокируем возможность юзать пробел вначале
      const target = event.target as HTMLInputElement;
      if (event.key === " " && !target.value) {
        event.preventDefault();
      }
    };
    // </>

    // modal
    const show = computed(() => {
      const { modal } = store.modal;
      return modal.editProduct;
    });
    const handleBack = () => {
      store.admin.updateParams({ page: 1 }, "products");
      store.admin.clearProducts();

      store.modal.closeModal("editProduct");
      store.modal.openModal("products");
    };
    const handleClose = () => {
      store.modal.closeModal("editProduct");
    };

    const { priceFormat } = usePriceFormat();
    watch(
      () => show.value,
      (status: boolean) => {
        if (status) {
          form.value.isWeight = product.value.isWeight;
        } else {
          form.value = {
            title: "",
            description: "",
            unit: "",
            price: null,
            priceKilo: null,
            quantityStock: null,
            isWeight: false,
          };
        }
      },
      { immediate: true }
    );
    // reset priceKilo
    watch(
      () => form.value.isWeight,
      (status: boolean) => {
        if (!status) {
          form.value.priceKilo = null;
        }
      },
      { immediate: true }
    );

    return {
      // state
      product,
      loading,

      form,
      packingOptions,
      handleSubmit,
      handlePrevent,

      // modal
      show,
      handleBack,
      handleClose,

      priceFormat,
    };
  },
});
</script>

<style lang="less" scoped>
button:disabled {
  pointer-events: none;
  opacity: 0.7;
}
.redact-block {
  input,
  textarea {
    text-align: left;
  }
}
</style>